//React
import React from 'react';
import { useEffect } from "react";

//Hooks
import { useMapInfo } from "../hooks/useMapInfo";
import { useMap } from "react-leaflet";
import { useMapUtil } from "../hooks/useMapUtil";

//Bootstrap
import { Card, Spinner } from "react-bootstrap";

function MapLoader() {
    const map = useMap();
    const { mapUtil } = useMapUtil();

    return (
        <>
            <div style={{ position: 'absolute', bottom: '0px', left: '10px', zIndex: 20000 }}>
                {mapUtil.loaders.filter((loader) => !loader.fullscreen).map((loader: any, index: number) => (
                    <Card className="mb-1" key={loader.id}>
                        <Card.Body className="d-flex p-1">
                            <Spinner className="align-self-center" style={{ marginLeft: '5px' }} animation="border" variant="primary" key={index} size="sm" />
                            <span className="align-self-center" style={{ marginLeft: '10px', fontWeight: 'bold'}}>{loader.text}</span>
                        </Card.Body>
                    </Card>
                ))};
            </div>
            <div style={{ position: 'absolute', top: '0px', left: '0px', zIndex: 20001, width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.5)', display: mapUtil.loaders.filter((loader) => loader.fullscreen).length > 0 ? 'block' : 'none' }}>
                {mapUtil.loaders.filter((loader) => loader.fullscreen).map((loader: any, index: number) => (
                    <div style={{ position: 'absolute', top: '50%', left: '50%', zIndex: 20002, display: 'block' }} className='d-flex flex-column align-center'>
                        <Spinner className="align-self-center" key={index} animation="border" variant="black" />
                        <span className="align-self-center mt-2" style={{ fontWeight: 'bold'}}>{loader.text}</span>
                    </div>
                ))};
            </div>
        </>
    );
}

export default MapLoader;
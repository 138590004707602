import { useDispatch, useSelector } from "react-redux";
import { addLoader, removeLoader, selectMapUtil } from "../store/mapUtilSlice";

export const useMapUtil = () => {
    const dispatch = useDispatch();
    const mapUtil = useSelector(selectMapUtil);

    const createLoader = (id: string, text: string, fullscreen: boolean = false) => {
        dispatch(removeLoader(id));
        dispatch(addLoader({
            id: id,
            text: text,
            fullscreen: fullscreen
        }));
    }

    const deleteLoader = (id: string) => {
        dispatch(removeLoader(id));
    }

    const isLoader = (id: string) => {
        return mapUtil.loaders.find((loader) => loader.id === id);
    }

    return {
        mapUtil,
        createLoader,
        deleteLoader,
        isLoader
    }
}
